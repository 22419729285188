import { DEFAULT_ACTIVE_CHAIN_ID } from "config/constants";
import { getAtlanticTokenABI, getPresaleABI, getPresaleInfo } from "utils/presale";
import multicall from 'utils/multicall';
import { getBalanceInEther } from "utils";
import { PresaleInfo } from "types/presale";
import { ethers } from "ethers";

export const fetchPresaleInfo = async (chainId: string): Promise<any> => {
    const selectedChainid = Number(chainId || DEFAULT_ACTIVE_CHAIN_ID);
    const presaleInfo = getPresaleInfo(selectedChainid);

    try {
        if( presaleInfo.length > 0 ){
            const info = presaleInfo[0]
            const calls1 = [
                {
                  address: info.contractAddress,
                  name: 'isSaleOpen',
                },
                {
                    address: info.contractAddress,
                    name: 'price',
                  },
              ];
              const [isSaleOpen, price] = await multicall(getPresaleABI(selectedChainid), calls1);
              return{
                data: {
                isSaleOpen,
                contractAddress: info.contractAddress,
                tokenAddress: info.tokenAddress,
                saleTokenAddress: info.saleTokenAddress
                }
              }
        } 
        // if( bridgeInfo.length > 0 ){
        //     const chainInfo = bridgeInfo[0];
        //     return {data: chainInfo};
        // }
    } catch (error: any) {
        console.log(error)
        return {
            data: {
            isSaleOpen: false,
            contractAddress: "",
            tokenAddress: "",
            saleTokenAddress: ""
            }
        };
    }
    return {
        data: {
        isSaleOpen: false,
        contractAddress: "",
        tokenAddress: "",
        saleTokenAddress: ""
        }
    };
};

export const fetchUserInfo = async (chainId: string, account: string): Promise<any> => {
    const selectedChainid = Number(chainId || DEFAULT_ACTIVE_CHAIN_ID);
    const presaleInfo = getPresaleInfo(selectedChainid);

    try {
        if( presaleInfo.length > 0 ){
            const info = presaleInfo[0]
            const calls1 = [
                {
                  address: info.tokenAddress,
                  name: 'balanceOf',
                  params: [account]
                },
              ];
              const [userBalance] = await multicall(getAtlanticTokenABI(selectedChainid), calls1);
              return{
                data: {
                    balance: ethers.utils.formatEther(userBalance[0])
                }
              }
        } 
        // if( bridgeInfo.length > 0 ){
        //     const chainInfo = bridgeInfo[0];
        //     return {data: chainInfo};
        // }
    } catch (error: any) {
        console.log(error)
        return {
            data: {
                balance: 0
            }
        };
    }
    return {
        data: {
            balance: 0
        }
    };
};