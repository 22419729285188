/* eslint-disable no-underscore-dangle */
import multicall, { multicallNative} from 'utils/multicall';
import pairAbi from 'config/abi/LpPair.json';
import ERC20 from 'config/abi/Erc20.json';
import { getBalanceInEther } from 'utils/formatBalance';
import { getTokens } from 'utils/tokens';
import { getTokensInfo } from 'utils/backend';
import { DEFAULT_ACTIVE_CHAIN_ID } from 'config/constants/chains';
import { getTokenPriceFromCGC } from 'utils/coingecko';
import { getTokenPriceFromDexTools } from 'utils/dextools';
import { BigNumber, ethers } from 'ethers';

const TAX_RATIO_DENOMINATOR = 10000;

export const fetchGlobalTokenData = async (chainId: string): Promise<any> => {

  try {
    const selectedChainid = Number(chainId || DEFAULT_ACTIVE_CHAIN_ID);
    const tokens = getTokens(selectedChainid);
    let circulatingSupply = 0;
    
    const offchainData = await getTokensInfo({});
    const onchainData = await Promise.all(
      tokens.map(async (token: any) => {

        return {
          ...token,
        };

        const tokenAddr = token.address;
        let buyTax;
        let sellTax;
        let burntAmnt = 0;
        console.log(token)
        const [totalSupplyRaw] = await multicall(ERC20, [
          {
            address: tokenAddr,
            name: 'totalSupply',
            params: [],
          },
        ]);

        let totalSupply = getBalanceInEther(totalSupplyRaw[0]);
        console.log(totalSupply)
        if (token.hasTax) {
          if( token.sellTax || token.buyTax ){
            buyTax = token.buyTax;
            sellTax = token.sellTax;
          }
          else{
            const [buyTaxRaw, sellTaxRaw] = await multicall(ERC20, [
              {
                address: tokenAddr,
                name: 'buyFee',
                params: [],
              },
              {
                address: tokenAddr,
                name: 'sellFee',
                params: [],
              },
            ]);
            buyTax = buyTaxRaw[0].toNumber() / TAX_RATIO_DENOMINATOR;
            sellTax = sellTaxRaw[0].toNumber() / TAX_RATIO_DENOMINATOR;
          }
        }

        //  TODO Fetch addresses from correct places.

        if( token.symbol === 'OBOL' || token.symbol === 'SMELT'){
          const [boilerSupplyRaw] = await multicall(ERC20, [
            {
              address: tokenAddr,
              name: 'balanceOf',
              params: ["0x6bB173673cc128Aa8709B2Aa8108f6f9521AE0A0"],
            },
          ]);
          const boilerSupply = getBalanceInEther(boilerSupplyRaw);
          const [fundSupplyRaw] = await multicall(ERC20, [
            {
              address: tokenAddr,
              name: 'balanceOf',
              params: ["0x0A10daD90b9C6FB8B87BFf3857A4B012890C53A5"],
            },
          ]);
          const fundSupply = getBalanceInEther(fundSupplyRaw);

          if( token.symbol === 'SMELT' ){
            const [rewardPoolSupplyRaw] = await multicall(ERC20, [
              {
                address: tokenAddr,
                name: 'balanceOf',
                params: ["0x7A1f47c8a26fD895228947ffc0482F3dD9c2cA29"],
              },
            ]);
            const rewardPoolSupply = getBalanceInEther(rewardPoolSupplyRaw);
            circulatingSupply = totalSupply - boilerSupply - rewardPoolSupply;
          }
          else {
            circulatingSupply = totalSupply - boilerSupply - fundSupply;
          }
        }
        else{
          circulatingSupply = totalSupply;
        }

        let tokenPriceUsd = 0;

        // if( token.cgcId  ){
        //   const tokenPricesRaw = await getTokenPriceFromCGC(token.cgcId);
        //   let tokenPrices2: any = {};
        //   Object.entries(tokenPricesRaw)
        //     .map((row) => ({ [row[0]]: row[1].usd }))
        //     .map((row) => {
        //       tokenPrices2 = { ...tokenPrices2, ...row };
        //       return row;
        //     });
        //   tokenPriceUsd = tokenPrices2[token.cgcId];
        // }
        
        if( token.dextoolsPair ) {
          try{
            console.log(token.dextoolsPair)
            // const res = await getTokenPriceFromDexTools(Number(chainId), token.dextoolsPair);
            // tokenPriceUsd = res;
            const [reserves] = await multicall(pairAbi, [
              {
                address: token.dextoolsPair,
                name: 'getReserves',
                params: [],
              },
            ]);
            if( reserves.length > 0 ){
              const token0Reserv = Number(ethers.utils.formatEther(reserves[0]))
              const token1Reserv =  Number(ethers.utils.formatEther(reserves[1]))
              if( token0Reserv > token1Reserv )
                tokenPriceUsd = token1Reserv / token0Reserv
              else 
                tokenPriceUsd = token0Reserv / token1Reserv
              console.log(tokenPriceUsd)
            }
            if( token.nativePair ){
              const [token0, token1] = await multicall(pairAbi, [
                {
                  address: token.nativePair,
                  name: 'token0',
                  params: [],
                },
                {
                  address: token.nativePair,
                  name: 'token1',
                  params: [],
                },
              ]);
              console.log(token0)
              console.log(token1)
              const [token0Decimals, token1Decimals, token0Balance, token1Balance] = await multicall(ERC20, [
                {
                  address: token0[0],
                  name: 'decimals',
                  params: [],
                },
                {
                  address: token1[0],
                  name: 'decimals',
                  params: [],
                },
                {
                  address: token0[0],
                  name: 'balanceOf',
                  params: [token.nativePair.toString()],
                },
                {
                  address: token1[0],
                  name: 'balanceOf',
                  params: [token.nativePair.toString()],
                }
              ]);

              console.log(token0Decimals)
              console.log(token1Decimals)

              let priceLp = 1;
              if( token0Decimals[0] === 6 ) {
                console.log("Price = ")
                const format0 = ethers.utils.formatUnits(BigNumber.from(token0Balance[0]), token0Decimals[0]);
                const format1 = ethers.utils.formatUnits(token1Balance[0], token1Decimals[0]);
                console.log(format0)
                priceLp = Number(format0) / Number(format1)
                console.log(priceLp)
              }
              else{
                console.log("Price2 = ")
                console.log(token0Balance)
                const format0 = ethers.utils.formatUnits(BigNumber.from(token0Balance[0]), token0Decimals[0]);
                const format1 = ethers.utils.formatUnits(token1Balance[0], token1Decimals[0]);
                console.log(format0)
                priceLp = Number(format1) / Number(format0)
                console.log(priceLp)
              }
              tokenPriceUsd *= (priceLp)
            }
          }
          catch( err ){
            // 
          }
        }
        if( token.nativeTokenAddress ){
          try{
            const [totalSupplyNativeRaw] = await multicallNative(ERC20, [
              {
                address: token.nativeTokenAddress,
                name: 'totalSupply',
                params: [],
              },
            ]);
            totalSupply = getBalanceInEther(totalSupplyNativeRaw[0]);
          }
          catch(err){
            // 
            console.log(err)
          }
        }

        return {
          ...token,
          buyTax,
          sellTax,
          priceInUsd: 0,
          priceInFtm: 0,
          circulationSupply: circulatingSupply,
          totalSupply,
          burntAmnt,
        };
      })
    );
    return {
      data: onchainData,
    };
  } catch (error: any) {
    console.log(error)
    return {
      data: [],
    };
  }
};
