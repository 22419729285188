import { createSlice } from '@reduxjs/toolkit';
import { PresaleInfo } from 'types/presale';
import { fetchPresaleInfo, fetchUserInfo } from './fetchPreSaleGlobalInfo';

interface PresaleState {
    info: PresaleInfo,
    userInfo: any
}

const initialState: PresaleState = {
    info: {isSaleOpen: false, contractAddress: "", tokenAddress: "", saleTokenAddress: ""},
    userInfo: {}
};

export const presaleSlice = createSlice({
    name: 'presale',
    initialState,
    reducers: {
        reset: (state) => {
            state.info = {isSaleOpen: false, contractAddress: "", tokenAddress: "", saleTokenAddress: ""}
            state.userInfo = {}
        },
        setPresaleInfo: (state, action) => {
            state.info = action.payload.data;
        },
        setUserPresaleInfo: (state, action) => {
            state.userInfo = action.payload.data;
        },
    },
});

export const { reset, setPresaleInfo, setUserPresaleInfo } = presaleSlice.actions;

export const fetchPresaleInfoAsync =
    (chainId: string) =>
        async (dispatch: any): Promise<void> => {
            const { data } = await fetchPresaleInfo(chainId);
            dispatch(
                setPresaleInfo({
                    data,
                })
            );
        };
export const fetchPresaleUserInfoAsync =
    (chainId: string, account: string) =>
        async (dispatch: any): Promise<void> => {
            const { data } = await fetchUserInfo(chainId, account);
            dispatch(
                setUserPresaleInfo({
                    data,
                })
            );
        };

export default presaleSlice.reducer;
