import Web3 from 'web3';
import { HttpProviderOptions } from 'web3-core-helpers';
import { AbiItem } from 'web3-utils';
import { Contract, ContractOptions } from 'web3-eth-contract';

import getRpcUrl from 'utils/getRpcUrl';
import { getItem } from 'utils/localstorage';

let chainId = getItem('selectedChainId');
const RPC_URL = getRpcUrl(Number(chainId));
const httpProvider = new Web3.providers.HttpProvider("https://rpc.degen.tips", { timeout: 10000 } as HttpProviderOptions);
const RPC_URL_BASE = getRpcUrl(Number("8453"));

const httpBASEProvider = new Web3.providers.HttpProvider(RPC_URL_BASE, { timeout: 10000 } as HttpProviderOptions);
const web3NoAccount = new Web3(httpProvider);
const web3NoAccountBASE = new Web3(httpBASEProvider);

const activeChainId = Number(process.env.REACT_APP_CHAIN_ID || '666666666');
const mainNetChainId = 666666666;
const testNetChainId = 4002;
/**
 * Provides a web3 instance using our own private provider httpProver
 */
const getWeb3 = (): Web3 => {
  chainId = getItem('selectedChainId');
  const RPC_URL2 = getRpcUrl(Number(chainId));
  const httpProvider2 = new Web3.providers.HttpProvider(RPC_URL2, { timeout: 10000 } as HttpProviderOptions);

  const web3 = new Web3(httpProvider2);
  return web3;
};
const getContract = (abi: AbiItem, address: string, contractOptions?: ContractOptions): Contract => {
  const web3 = getWeb3();
  return new web3.eth.Contract(abi as unknown as AbiItem, address, contractOptions);
};

const getWeb3NoAccount = (): Web3 => web3NoAccount;
const getWeb3NoAccountBASE = (): Web3 => web3NoAccountBASE;

const getProviderBASE = (): any => httpBASEProvider;

export { getWeb3, getContract, httpProvider, getWeb3NoAccount,getWeb3NoAccountBASE,getProviderBASE, activeChainId, mainNetChainId, testNetChainId };
