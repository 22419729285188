import { chains, tokens } from 'config/constants/assets';

const presaleInfo = [
    {
        chainId: 169,
        contractAddress: "0x05c733e5e2c2138caf25b70bb15b4f26931f8265",
        tokenAddress: "0x3a700cc65db2ce1a096c5d691bcacae56c41431d",
        saleTokenAddress: ""
    }
]

const presaleInfos = [...presaleInfo];

export {presaleInfos}