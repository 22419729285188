import { icons } from 'config/constants/assets';

export interface SocialType {
  key: string;
  url: string;
  icon: any;
}

export const socials: SocialType[] = [
  { key: 'telegram', url: 'https://t.me/+kSMbkXHS_rE1MGY1', icon: ""},
  { key: 'twitter', url: 'https://x.com/degenfund_?s=21&t=2c9obRSBhVGabMQRabQqbA', icon: "" },
];
