import { createSlice } from '@reduxjs/toolkit';
import { FarmInfo } from 'types/stake';
import { fetchGlobalFarmData } from './fetchStakeGlobalInfo';

interface StakeState {
    data: FarmInfo[];
    userInfo: any;
    loaded: boolean;
}

const initialState: StakeState = {
    data: [],
    userInfo: {},
    loaded: false
};

export const stakeSlice = createSlice({
    name: 'stake',
    initialState,
    reducers: {
        reset: (state) => {
            state.data = []
            state.userInfo = {}
            state.loaded = false;
        },
        setFarmGlobalData: (state, action) => {
            state.data = action.payload.data.map((row: any, i: number) => ({
              ...row,
              userInfo: state.data[i]?.userInfo || {},
            }));
            state.loaded = true;
          },

        setUserPresaleInfo: (state, action) => {
            state.userInfo = action.payload.data;
        },
    },
});

export const { reset, setFarmGlobalData, setUserPresaleInfo } = stakeSlice.actions;


export const fetchFarmGlobalDataAsync =
  (chainId: string) =>
  async (dispatch: any): Promise<void> => {
    const { data } = await fetchGlobalFarmData(chainId);

    dispatch(
      setFarmGlobalData({
        data,
      })
    );
  };

// export const fetchPresaleInfoAsync =
//     (chainId: string) =>
//         async (dispatch: any): Promise<void> => {
//             const { data } = await fetchPresaleInfo(chainId);
//             dispatch(
//                 setPresaleInfo({
//                     data,
//                 })
//             );
//         };
// export const fetchPresaleUserInfoAsync =
//     (chainId: string, account: string) =>
//         async (dispatch: any): Promise<void> => {
//             const { data } = await fetchUserInfo(chainId, account);
//             dispatch(
//                 setUserPresaleInfo({
//                     data,
//                 })
//             );
//         };

export default stakeSlice.reducer;
