import { createSlice } from '@reduxjs/toolkit';

import { TokenInfo } from 'types/token';

import { fetchGlobalTokenData } from './fetchGlobalTokens';

interface TokensState {
  data: TokenInfo[];
}

const initialState: TokensState = {
  data: [],
};

export const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    reset: (state) => {
      state.data = [];
    },

    setTokenGlobalData: (state, action) => {
      state.data = [...action.payload.data];
    },
  },
});

export const { reset, setTokenGlobalData } = tokenSlice.actions;

// fetch global token info
export const fetchTokenGlobalDataAsync =
  (chainId: string) =>
  async (dispatch: any): Promise<void> => {
    const { data } = await fetchGlobalTokenData(chainId);

    dispatch(
      setTokenGlobalData({
        data,
      })
    );
  };

export default tokenSlice.reducer;
