import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  isWalletConnectModalOpen: boolean;
  isAccountModalOpen: boolean;
  action: string;
  actionStatus: string;
  pendingTxHash: string;
  actionError: any;
} = {
  isWalletConnectModalOpen: false,
  isAccountModalOpen: false,
  action: '',
  actionStatus: '',
  pendingTxHash: '',
  actionError: undefined,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setIsWalletConnectModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isWalletConnectModalOpen = action.payload;
    },

    setIsAccountModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isAccountModalOpen = action.payload;
    },

    setActionStatus: (state, action: PayloadAction<string>) => {
      state.actionStatus = action.payload;
    },

    setPendingTxHash: (state, action: PayloadAction<string>) => {
      state.pendingTxHash = action.payload;
    },

    setActionError: (state, action: PayloadAction<any>) => {
      state.actionError = action.payload;
    },
  },
});

export const { setIsWalletConnectModalOpen, setIsAccountModalOpen, setActionStatus, setPendingTxHash, setActionError } =
  modalSlice.actions;

export default modalSlice.reducer;
