import { getBalanceInEther } from 'utils/formatBalance';
import { getStakes, getMasterChefAbi, getMasterChefAddress, getRewardEmissionRateFunctionName } from 'utils/stake';
import { DEFAULT_ACTIVE_CHAIN_ID } from 'config/constants/chains';
import ERC20 from 'config/abi/Erc20.json';
import multicall from 'utils/multicall';
import { StakeCategory } from 'types/stake';
import DeployerAbi from "config/abi/Deployer.json"
import { getDeployAddress } from 'utils/deployTokens';
// fetch reward pool farms (smelt reward pools and dead pools)
export const fetchDeployedTokens = async (account: string): Promise<any> => {
  try {
    // get farm onchain inf
        const [deployedCount] = await multicall(
          DeployerAbi,
          [
            {
              address: getDeployAddress(),
              name: 'deployedCount',
              params: [],
            },
          ]
        );
        const indexes = [];
        
        for( let i = deployedCount - 1; i >= 5; i-=1 ){
            indexes.push(i);
        }

        const tokensDeployed = await Promise.all(
            indexes.map(async (row: any) => { 
                const [deployedTokenAddress] = await multicall(
                    DeployerAbi,
                    [
                      {
                        address: getDeployAddress(),
                        name: 'deployedTokens',
                        params: [row],
                      },
                    ]
                );
                const [info] = await multicall(
                    DeployerAbi,
                    [
                      {
                        address: getDeployAddress(),
                        name: 'tokensInfo',
                        params: [deployedTokenAddress[0]],
                      },
                    ]
                );

                const [symbol] = await multicall(
                    ERC20,
                    [
                      {
                        address: deployedTokenAddress[0],
                        name: 'symbol',
                        params: [],
                      },
                    ]
                );
                let isUserToken = false;
                if( account ){
                    if( info.deployerAddress === account ){
                        isUserToken = true
                    }
                }
                return {info, symbol: symbol[0], isUserToken, logo: ""};
            } 
            ) 
        )

        if( account ){

        }
        return tokensDeployed;
  } catch (error: any) {
    return [];
  }
};

export const fetchDeployedTokensGlobalInfo = async (account: string): Promise<any> => {
  const smeltRewardPoolFarmsData = await fetchDeployedTokens(account);

  return {
    data: [...smeltRewardPoolFarmsData],
  };
};
