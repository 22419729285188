export enum ConnectorNames {
  Metamask = 'metamask',
  WalletConnectV2 = 'walletconnectV2',
  Coinbase = 'coinbase',
}

export type Login = (connectorId: ConnectorNames, chainId?: string) => void;

export interface Config {
  title: string;
  icon: string;
  connectorId: ConnectorNames;
  bgColor?: string;
}
