import { presaleInfos } from 'config/constants/presale';
import DeployAbi from 'config/abi/Deployer.json';
import AtlanticTokenAbi from 'config/abi/AtlanticToken.json';

export const getDeployAddress = () => {
    return "0x33caF4ce1bBE6C1BD47DF1012091ff888942448D"; // 0xBA7297E0c7c45cf4dDde632f32D7127BeDc64A5C
};


export const getDeployTokenABI = () => {
    return DeployAbi;
};
