import { createSlice } from '@reduxjs/toolkit';
import { FarmInfo } from 'types/stake';
import { fetchDeployedTokensGlobalInfo } from './fetchDeployedTokensGlobalInfo';

interface DeployedTokens {
    deployedTokens: any
}

const initialState: DeployedTokens = {
  deployedTokens: [],
};

export const deployedTokensSlice = createSlice({
    name: 'deployedTokens',
    initialState,
    reducers: {
        reset: (state) => {
            state.deployedTokens = []
        },
        setFarmGlobalData: (state, action) => {
            state.deployedTokens = action.payload.data;
          },

        setUserPresaleInfo: (state, action) => {
        },
    },
});

export const { reset, setFarmGlobalData, setUserPresaleInfo } = deployedTokensSlice.actions;


export const fetchDeployedTokensDataAsync =
  (account: string) =>
  async (dispatch: any): Promise<void> => {
    const { data } = await fetchDeployedTokensGlobalInfo(account);

    dispatch(
      setFarmGlobalData({
        data,
      })
    );
  };

export default deployedTokensSlice.reducer;
