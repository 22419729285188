import { createContext, ReactNode, useRef, useState } from 'react';
import { Box, styled } from '@mui/material';

import { Notification } from 'types/notification';
import NotificationCard from './NotificationCard';

// notification container
const NotificationContainer = styled(Box)(({theme}) => ({
  position: 'fixed',
  bottom: '10px',
  right: '10px',
  display: 'flex',
  flexDirection: 'column-reverse',
  gap: '12px',
  [theme.breakpoints.up('xs')]: {
    bottom: '10px'
  },
  [theme.breakpoints.up('sm')]: {
    bottom: '70px',
  },
}));

interface NotificationContextType {
  notifications: Notification[];
  onShowNotification: (notification: Notification) => void;
  onHideNotification: (notificationId: string) => void;
}

export const NotificationContext = createContext<NotificationContextType>({} as NotificationContextType);

type Props = {
  children: ReactNode;
};

const NotificationProvider = ({ children }: Props) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const notificationsRef: { current: Notification[] | undefined } = useRef();
  notificationsRef.current = notifications;

  const onHideNotification = (notificationId: string | undefined) => {
    if (!notificationId) return;

    const currentNotifications = notificationsRef.current || [];
    setNotifications(currentNotifications.filter((row) => row.id !== notificationId));
  };

  const onShowNotification = (notification: Notification) => {
    const notificationId = Date.now().toString();
    const currentNotifications = notificationsRef.current || [];

    // hide prev notification
    const lastNotification = currentNotifications.slice(-1)[0];
    if (lastNotification && lastNotification.id) {
      setTimeout(() => {
        onHideNotification(lastNotification.id);
      }, 500);
    }

    setNotifications([...currentNotifications, { ...notification, id: notificationId }]);

    setTimeout(() => {
      onHideNotification(notificationId);
    }, 30000);
  };

  const onHideAll = (notificationId: string) => {
    setNotifications([]);
  };

  return (
    <NotificationContext.Provider value={{ notifications, onShowNotification, onHideNotification }}>
      {children}
      <NotificationContainer>
        {notifications.map((notification: any) => (
          <NotificationCard data={notification} key={notification.id} />
        ))}
      </NotificationContainer>
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
