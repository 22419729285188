import { ApolloClient, gql, InMemoryCache } from '@apollo/client';

import { GRAPHQL_URL } from 'config/constants/backend';

export const graphqlClient = new ApolloClient({
  uri: GRAPHQL_URL,
  cache: new InMemoryCache(),
});

const tokensQuery = `
  query tokensQuery($limit: Int!, $skip: Int!) {
    tokensMany(limit: $limit, skip: $skip)
      {
        tokenAddress
        symbol
        decimals
        priceInUSD
        priceUSD
        priceInWFTM
        tradeVolume
      }
  }
`;

export const getTokensInfo = async ({ limit, skip }: { limit?: number; skip?: number }) => {
  try {
    const result = await graphqlClient.query({
      query: gql(tokensQuery),
      variables: {
        limit: limit || 50,
        skip: skip || 0,
      },
      fetchPolicy: 'network-only',
    });

    return result.data.tokensMany;
  } catch (err) {
    return [];
  }
};

const tokensQueryMarketVolumes = `
  query tokensQueryMarketVolumes($collectionNFT: String!, $startTime: Int!, $endTime: Int!) {
    getMarketVolumesByDate(collectionNFT: $collectionNFT, startTime: $startTime, endTime: $endTime)
    {
      collectionNFT,
      counterBuy,
      counterSell,
      maxPriceBuy,
      maxPriceSell,
      midPriceBuy,
      midPriceSell,
      minPriceSell,
      volumeBuy,
      volumeSell,
      timeStamp
    }
  }
`;

export const getMarketVolume = async ({ collectionNFT, startTime, endTime }: { collectionNFT: string; startTime: number; endTime: number }) => {
  try {
    const result = await graphqlClient.query({
      query: gql(tokensQueryMarketVolumes),
      variables: {
        collectionNFT: collectionNFT.toLocaleLowerCase(),
        startTime,
        endTime,
      },
      fetchPolicy: 'network-only',
    });
    return result.data.getMarketVolumesByDate;
  } catch (err) {
    return [];
  }
};

const queryMarketSells = `
  query getTransSellsByDate($collectionNFT: String!, $startTime: Int!, $endTime:Int!){
    getTransSellsByDate(collectionNFT: $collectionNFT, startTime: $startTime, endTime: $endTime){
      amount,
      collectionNFT,
      blockNumber,
      expireTime,
      nftId,
      paymentToken,
      seller,
      timeStamp
    }
  }
`;

export const getMarketSells = async ({ collectionNFT, startTime, endTime }: { collectionNFT: string; startTime: number; endTime: number }) => {
  try {
    const result = await graphqlClient.query({
      query: gql(queryMarketSells),
      variables: {
        collectionNFT: collectionNFT.toLocaleLowerCase(),
        startTime,
        endTime,
      },
      fetchPolicy: 'network-only',
    });
    return result.data.getTransSellsByDate;
  } catch (err) {
    return [];
  }
};

const queryMarketBuys = `
query getTransBuysByDate($collectionNFT: String!, $startTime: Int!, $endTime:Int!){
  getTransBuysByDate(collectionNFT: $collectionNFT, startTime: $startTime, endTime: $endTime){
    amount,
    blockNumber,
    buyer,
    collectionNFT,
    nftId,
    paymentToken,
    serviceFee,
    timeStamp,
    transactionHash
  }
}
`;

export const getMarketBuys = async ({ collectionNFT, startTime, endTime }: { collectionNFT: string; startTime: number; endTime: number }) => {
  try {
    const result = await graphqlClient.query({
      query: gql(queryMarketBuys),
      variables: {
        collectionNFT: collectionNFT.toLocaleLowerCase(),
        startTime,
        endTime,
      },
      fetchPolicy: 'network-only',
    });
    return result.data.getTransBuysByDate;
  } catch (err) {
    return [];
  }
};