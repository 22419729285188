import { chains } from 'config/constants/assets';
import { StakeCategory } from 'types/stake';

const stakingInfo = [
    {
        name: 'DFund',
        poolId: 0,
        stakingToken: '0x0B946D939bb93609Fcce42220180E5C81B642786',
        stakingToken0: '0x1539C63037D95f84A5981F96e43850d1451b6216', // obol
        stakingToken1: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', // wFTM
        receiptToken: '0x4ef20669E38751E4a585638d12dCFc6FF3635Dd1', // receipt token
        rewardTokenName: 'Smelt',
        rewardToken: '0x141FaA507855E56396EAdBD25EC82656755CD61e', // smelt
        rewardTokenDecimal: 18,
        // tokenImage: tokenImages.obolFtmLp,
        // rewardTokenImage: tokenImages.rewardSmelt,
        masterChefAddress: '0xe11CD52De12a86400311e0D2884aC9B542eEd05e', // SmeltRewardPool
        farmType: 'smeltRewardPool',
        chainId: 666666666,
        category: StakeCategory.ACTIVE,
        location: 'staking',
        autocompounder: 'https://matrix.farm/?provider=basedV2',
        place: StakeCategory.ACTIVE
      },
]

const degenMasterChefInfos = [
    {
      address: '0xe11CD52De12a86400311e0D2884aC9B542eEd05e',
      type: 'smeltRewardPool',
      chainId: 666666666,
      category: StakeCategory.ACTIVE,
    }
]  

const masterChefInfos = [...degenMasterChefInfos];
const stakingInfos = [...stakingInfo];

export {stakingInfos, masterChefInfos}