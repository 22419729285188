import React from 'react';
import { Box, Dialog, Grid, styled, Typography } from '@mui/material';
import { Login } from 'types/wallet';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setIsWalletConnectModalOpen } from 'state/modal/modalSlice';

import WalletCard from './WalletCard';
import config from './config';

const ModalDialog = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-container > .MuiPaper-root': {
    // borderRadius: '12px',
    maxWidth: '250px',
    width: '100%',
    background: theme.palette.mode === 'dark' ? '#16191E' : '#16191E',
    padding: '30px 30px',
    border: '1px solid #5F5D9C',
  },
}));

const ModalTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '19px',
  lineHeight: '25px',
  color: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
}));

interface Props {
  login: Login;
}

const ConnectModal: React.FC<Props> = ({ login }) => {
  const { isWalletConnectModalOpen } = useAppSelector((state) => state.modal);
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(setIsWalletConnectModalOpen(false));
  };

  const onDismiss = () => {
    onClose();
  };

  return (
    <ModalDialog maxWidth="xs" onClose={onClose} open={isWalletConnectModalOpen}>
      {/* <Box>
        <ModalTitle>Connect Wallet</ModalTitle>
      </Box> */}
      <Box sx={{ marginTop: '0px' }}>
        <Grid container spacing="12px">
          {config.map((entry) => (
            <Grid item key={entry.title} xs={12}>
              <WalletCard login={login} onDismiss={onDismiss} walletConfig={entry} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </ModalDialog>
  );
};

export default ConnectModal;
