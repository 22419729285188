import { FC } from 'react';
import { Box, styled, Typography, useMediaQuery, useTheme } from '@mui/material';

import { socials, SocialType } from 'config/constants/footer';
import XIcon from '@mui/icons-material/X';
import TelegramIcon from '@mui/icons-material/Telegram';

const AppFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '26px 32px',
  marginTop: '0px',
  height: '100px',
  background: 'transparent',

  [theme.breakpoints.up('xs')]: {
    // padding: '26px 32px 17px 32px',
  },
  [theme.breakpoints.up('sm')]: {
    // padding: '26px 32px',
  },
}));

const FooterContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '16px',
  width: '100%',
  maxWidth: '851px',
  margin: 'auto',

  [theme.breakpoints.up('xs')]: {
    flexDirection: 'column',
    gap: '25px',
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    gap: '16px',
  },
}));

// copyright section
const CopyRightSection = styled(Box)(() => ({}));

const CopyRightSectionText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '21px',
  color: 'rgba(255, 255, 255, 0.7)',

  [theme.breakpoints.up('xs')]: {
    fontSize: '11px',
    lineHeight: '17px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    lineHeight: '21px',
  },
}));

// name section
const NameSection = styled(Box)(() => ({}));

const NameText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '21px',
  color: theme.palette.text.primary,
}));

// social section
const SocialSection = styled(Box)(() => ({
  display: 'flex',
  gap: '20px',
  position: 'relative',
  textAlign: 'center',
  alignItems: 'center',
}));

const SocialItem = styled(Box)(() => ({
  cursor: 'pointer',
}));

const SocialIcon = styled('img')(() => ({
  minWidth: '22px',
}));

const Footer: FC = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const onClickSocialItem = (item: SocialType) => {
    window.open(item.url, '_blank');
  };

  return (
    <AppFooter>
      <FooterContent>
        {!isMobile && (
          <CopyRightSection>
            <CopyRightSectionText>{`Copyright © DegenFund 2024 `}</CopyRightSectionText>
          </CopyRightSection>
        )}
        <SocialSection>
          {socials.map((row: SocialType) => (
            <SocialItem key={row.key} onClick={() => onClickSocialItem(row)}>
              {/* <SocialIcon alt="icon" src={row.icon} /> */}
              {row.key === "twitter" &&
              <XIcon sx={{width: '18px'}} />
              }
              {row.key === "telegram" &&
              <TelegramIcon />
              }
            </SocialItem>
          ))}
        </SocialSection>
        {isMobile && (
          <CopyRightSection>
            <CopyRightSectionText>{`Copyright © DegenFund 2024 `}</CopyRightSectionText>
          </CopyRightSection>
        )}
      </FooterContent>
    </AppFooter>
  );
};

export default Footer;
