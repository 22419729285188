// Atlantis
import AtlantisMainImg from 'assets/main_img.jpeg'
import GoalImg from 'assets/goal.png'
import AtlImg from 'assets/atl_logo.svg'

// wallets
import metamask from 'assets/images/wallets/metamask.svg';
import walletConnect from 'assets/images/wallets/wallet-connect.svg';
import coinbase from 'assets/images/wallets/coinbase.svg';
import other from 'assets/images/wallets/other.svg';
import dexscreener from 'assets/images/wallets/dex-screener.png';

// chains
import ethereumLogo from 'assets/images/chains/ethereum.svg';
import fantomLogo from 'assets/images/chains/fantom.svg';
import fantomLogo1 from 'assets/images/chains/fantom1.svg';
import avalancheLogo from 'assets/images/chains/avalanche.svg';
import polygonLogo from 'assets/images/chains/polygon.svg';
import polygonLogo1 from 'assets/images/chains/polygon1.svg';
import zkChainLogo from 'assets/images/chains/zksync.svg'
import baseChainLogo from 'assets/images/chains/baseChain.jpg';
import polygonLogo2 from 'assets/images/chains/polygon.jpeg';
// header
import chevronDownIcon1 from 'assets/images/icons/chevron-down-icon1.svg';
import DegenFarm from 'assets/degen.svg';

import HamburgerIcon from 'assets/images/icons/hamburger-icon.svg'

export const icons = {
  atlantisMain: AtlantisMainImg,
  atlToken: AtlImg,
  goal: GoalImg,
  degenFarm: DegenFarm,
  chevronDown1: chevronDownIcon1,
  hamburger: HamburgerIcon
};

export const images = {
};

export const protocols = {
};

export const tokens = {
  rewardFtm: fantomLogo1,
};

export const accountTokens = {
};

export const wallets = {
  metamask,
  walletConnect,
  coinbase,
  other,
  dexscreener,
};

export const chains = {
  ethereum: ethereumLogo,
  fantom: fantomLogo,
  fantom1: fantomLogo1,
  avalanche: avalancheLogo,
  polygon: polygonLogo,
  polygon1: polygonLogo1,
  zkSync: zkChainLogo,
  base: baseChainLogo,
  polygon2: polygonLogo2
};

export const staking = {
};

export const overviewPage = {
}