import { FC } from 'react';
import { Button, ButtonProps, styled } from '@mui/material';

const StyledButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Prompt',
  width: '100%',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '21px',
  padding: '8px 19px',
  // background: theme.palette.common.gradient,
  // color: 'rgba(39, 40, 42, 0.8)',
  color: 'white',
  borderColor: 'white',
  // boxShadow:
    // '0px 100px 80px rgba(0, 0, 0, 0.07), 0px 37.4782px 33.4221px rgba(0, 0, 0, 0.0449519), 0px 15.8837px 17.869px rgba(0, 0, 0, 0.0380991), 0px 5.95146px 10.0172px rgba(0, 0, 0, 0.0347608), 0px 1.33314px 5.32008px rgba(0, 0, 0, 0.030586), 0px -0.286313px 2.21381px rgba(0, 0, 0, 0.0216513)',
  borderRadius: '0px',
  whiteSpace: 'nowrap',
}));

interface ContainedButtonProps extends ButtonProps {
  disabled?: boolean;
  colorType?: string;
}

const ContainedButton: FC<ContainedButtonProps> = ({ children, disabled, onClick, ...props }) => (
  <StyledButton disabled={disabled} onClick={onClick} {...props}>
    {children}
  </StyledButton>
);

export { ContainedButton };
