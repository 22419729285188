import { FC } from 'react';
import { Box, styled } from '@mui/material';
import { useWeb3React } from '@web3-react/core';

import { useAppDispatch } from 'state/hooks';
import { setIsAccountModalOpen } from 'state/modal/modalSlice';
import { icons, images } from 'config/constants/assets';

const AccountDropdown = styled(Box)(() => ({
  cursor: 'pointer',
}));

const AccountDropdownOrigin = styled(Box)(() => ({
  display: 'flex',
}));

const AccountPhoto = styled('img')(({ theme }) => ({
  marginRight: '10px',
  borderRadius: '50%',

  [theme.breakpoints.up('xs')]: {
    height: '37px',
    width: '37px',
  },
  [theme.breakpoints.up('sm')]: {
    height: '50px',
    width: '50px',
  },
}));

const ToggleIcon = styled('img')(() => ({}));

interface AccountButtonProps {
  image: string;
}

const AccountButton: FC<AccountButtonProps> = ({ image }) => {
  const dispatch = useAppDispatch();
  const { account } = useWeb3React();

  const onClickAccount = () => {
    if (account) {
      dispatch(setIsAccountModalOpen(true));
    }
  };

  return (
    <AccountDropdown>
      <AccountDropdownOrigin onClick={onClickAccount}>
        <AccountPhoto alt="account" src={image || ""} />
        {/* <ToggleIcon alt="toggle" src={icons.chevronDown} /> */}
      </AccountDropdownOrigin>
    </AccountDropdown>
  );
};

export { AccountButton };
