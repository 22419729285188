import { createSlice } from '@reduxjs/toolkit';

interface ChainState {
  selectedChainId: string;
  isWalletChecked: boolean;
}

const initialState: ChainState = {
  selectedChainId: '',
  isWalletChecked: false
};

export const chainSlice = createSlice({
  name: 'chain',
  initialState,
  reducers: {
    reset: (state) => {
      state.selectedChainId = '';
    },

    setSelectedChainId: (state, action) => {
      state.selectedChainId = action.payload;
    },
    setIsWalletChecked: (state, action) => {
      state.isWalletChecked = action.payload;
    },
  },
});

export const { reset, setSelectedChainId, setIsWalletChecked } = chainSlice.actions;

export default chainSlice.reducer;
