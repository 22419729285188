import { icons, tokens as tokenImages } from 'config/constants/assets';

const fantomTokenInfos = [
  {
    address: '0x0B946D939bb93609Fcce42220180E5C81B642786',
    symbol: 'DFund',
    decimals: 18,
    tokenImage: icons.degenFarm,
    chainId: 666666666,
    hasTax: true,
    visible: true,
    sellTax: 0.0001,
    buyTax: 0,
    pairedTokenAddress: '0x9c0Dd6BA0E2c611585c75F06f024BC8826FdB446',
    hasPair: true,
    showNativePrice: true,
    isStake: true
  }
]

const tokenInfos = [...fantomTokenInfos];
export { tokenInfos};
