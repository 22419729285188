import { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";

import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { Web3ReactProvider } from '@web3-react/core';
import { connectors } from 'utils/web3React';
import NotificationProvider from 'context/NotificationContext';

import Router from './routes';
import { store } from './state/store';
import { ThemeConfig } from './theme';

function App(): JSX.Element {
  return (
    <Web3ReactProvider connectors={connectors}>
      <BrowserRouter>
        <Provider store={store}>
          <ThemeConfig>
            <NotificationProvider>
              <Suspense fallback={<CircularProgress sx={{ margin: '50px auto' }} />}>
                <Router />
              </Suspense>
            </NotificationProvider>
          </ThemeConfig>
        </Provider>
        </BrowserRouter>
    </Web3ReactProvider>
  );
}

export default App;
